import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import CardModal from "../components/layouts/CardModal"
import heroSliderData from "../assets/fake-data/data-slider-2"
import SliderStyle1 from "../components/slider/SliderStyle1"
import avt from "src/assets/images/avatar/avt-author-tab.jpg"
import CrsHomeList from "./crs/CrsHomeList"

const Home = () => {
  const [menuTab] = useState([
    {
      class: "active",
      name: "ALL"
    }
  ])

  return (
    <div className="authors-2">
      <Header />
      <SliderStyle1 data={heroSliderData} />
      <section className="tf-section authors">
        <div className="themesflat-container">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex">
              <div className="feature-profile">
                <img src={avt} alt="Axies" className="avatar" style={{ width: "190px" }} />
              </div>
              <div className="infor-profile">
                <h2 className="title">Joel</h2>
                <p className="content" style={{ marginBottom: "0px" }}>
                  풀스택 AI개발자 / DevOps 엔지니어 / 클라우드 엔지니어
                </p>
                <p className="content">코드튜터 대표강사</p>
              </div>
              <div className="widget-social style-3">
                {/*
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li className="style-2">
                    <Link to="#">
                      <i className="fab fa-telegram-plane"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-youtube"></i>
                    </Link>
                  </li>
                  <li className="mgr-none">
                    <Link to="#">
                      <i className="icon-fl-tik-tok-2"></i>
                    </Link>
                  </li>
                </ul>
                */}
                <div className="btn-profile">
                  <Link to="/login" className="sc-button style-1 follow">
                    튜터소개
                  </Link>
                </div>
              </div>
            </div>

            <CrsHomeList />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home
