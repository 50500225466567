import React, { useState, useEffect } from "react"
import Uppy from "@uppy/core"
import Tus from "@uppy/tus"
import { Dashboard, DashboardModal, DragDrop, ProgressBar, FileInput } from "@uppy/react"
import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

//https://codesandbox.io/p/sandbox/react-file-upload-zoff7s?file=%2Fpackage.json%3A16%2C26
const UppyTest = () => {
  const [uppy] = useState(() =>
    new Uppy({
      id: "uppy",
      autoProceed: false,
      debug: true,
      restrictions: {
        maxNumberOfFiles: 7
        /*
      maxFileSize: 20000000,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/*', '.mp4']
      */
      } //https://codetutor.run/api/crs/uploadUrl
    }).use(Tus, { endpoint: "http://localhost:38080/api/crs/uploadUrl", chunkSize: 10 * 1024 * 1024 })
  )

  uppy.setOptions({
    locale: {
      strings: {
        noInternetConnection: "인터넷에 연결이 해제되었습니다.",
        connectedToInternet: "인터넷에 연결이 완료되었습니다.",

        // When `inline: false`, used as the screen reader label for the button that closes the modal.
        closeModal: "Close Modal",
        // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
        addMoreFiles: "추가파일 선택",
        addingMoreFiles: "추가파일 선택",
        // Used as the header for import panels, e.g., “Import from Google Drive”.
        importFrom: "Import from %{name}",
        // When `inline: false`, used as the screen reader label for the dashboard modal.
        dashboardWindowTitle: "Uppy Dashboard Window (Press escape to close)",
        // When `inline: true`, used as the screen reader label for the dashboard area.
        dashboardTitle: "Uppy Dashboard",
        // Shown in the Informer when a link to a file was copied to the clipboard.
        copyLinkToClipboardSuccess: "Link copied to clipboard.",
        // Used when a link cannot be copied automatically — the user has to select the text from the
        // input element below this string.
        copyLinkToClipboardFallback: "Copy the URL below",
        // Used as the hover title and screen reader label for buttons that copy a file link.
        copyLink: "Copy link",
        back: "뒤로가기",
        // Used as the screen reader label for buttons that remove a file.
        removeFile: "Remove file",
        // Used as the screen reader label for buttons that open the metadata editor panel for a file.
        editFile: "Edit file",
        // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
        editing: "Editing %{file}",
        // Used as the screen reader label for the button that saves metadata edits and returns to the
        // file list view.
        finishEditingFile: "Finish editing file",
        saveChanges: "Save changes",
        // Used as the label for the tab button that opens the system file selection dialog.
        myDevice: "My Device",
        dropHint: "Drop your files here",
        // Used as the hover text and screen reader label for file progress indicators when
        // they have been fully uploaded.
        uploadComplete: "파일업로드 완료",
        uploadPaused: "파일업로드 중지",
        // Used as the hover text and screen reader label for the buttons to resume paused uploads.
        resumeUpload: "Resume upload",
        // Used as the hover text and screen reader label for the buttons to pause uploads.
        pauseUpload: "Pause upload",
        // Used as the hover text and screen reader label for the buttons to retry failed uploads.
        retryUpload: "Retry upload",
        // Used as the hover text and screen reader label for the buttons to cancel uploads.
        cancelUpload: "파일업로드 취소",
        // Used in a title, how many files are currently selected
        xFilesSelected: {
          0: "%{smart_count}개 파일 선택",
          1: "%{smart_count}개 파일 선택"
        },
        uploadingXFiles: {
          0: "%{smart_count}개 파일 업로드",
          1: "%{smart_count}개 파일 업로드"
        },
        processingXFiles: {
          0: "%{smart_count}개 파일 업로드중입니다.",
          1: "%{smart_count}개 파일 업로드중입니다."
        },
        // The "powered by Uppy" link at the bottom of the Dashboard.
        poweredBy: "Powered by %{uppy}",
        addMore: "Add more",
        editFileWithFilename: "Edit file %{file}",
        save: "Save",
        cancel: "취소",
        dropPasteFiles: "파일을 드래그해서 올려주세요%{browseFiles}",
        dropPasteFolders: "Drop files here or %{browseFolders}",
        dropPasteBoth: "Drop files here, %{browseFiles} or %{browseFolders}",
        dropPasteImportFiles: "Drop files here, %{browseFiles} or import from:",
        dropPasteImportFolders: "Drop files here, %{browseFolders} or import from:",
        dropPasteImportBoth: "Drop files here, %{browseFiles}, %{browseFolders} or import from:",
        importFiles: "Import files from:",
        browseFiles: "[파일선택]",
        browseFolders: "browse folders",
        recoveredXFiles: {
          0: "We could not fully recover 1 file. Please re-select it and resume the upload.",
          1: "We could not fully recover %{smart_count} files. Please re-select them and resume the upload."
        },
        recoveredAllFiles: "We restored all files. You can now resume the upload.",
        sessionRestored: "Session restored",
        reSelect: "Re-select",
        missingRequiredMetaFields: {
          0: "Missing required meta field: %{fields}.",
          1: "Missing required meta fields: %{fields}."
        },

        uploading: "업로드중...",
        complete: "업로드 완료",
        uploadFailed: "업로드 실패",
        retry: "다시시도",
        done: "닫기",
        resume: "다시시작",
        paused: "일시중단",
        uploadXFiles: {
          0: "%{smart_count}개 파일 업로드",
          1: "%{smart_count}개 파일 업로드"
        },
        filesUploadedOfTotal: {
          0: "%{smart_count}개 중 %{complete}개의 파일업로드",
          1: "%{smart_count}개 중 %{complete}개의 파일업로드"
        }

        /*
        // When `inline: false`, used as the screen reader label for the button that closes the modal.
        closeModal: 'Close Modal',
        // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
        addMoreFiles: 'Add more files',
        addingMoreFiles: 'Adding more files',
        // Used as the header for import panels, e.g., “Import from Google Drive”.
        importFrom: 'Import from %{name}',
        // When `inline: false`, used as the screen reader label for the dashboard modal.
        dashboardWindowTitle: 'Uppy Dashboard Window (Press escape to close)',
        // When `inline: true`, used as the screen reader label for the dashboard area.
        dashboardTitle: 'Uppy Dashboard',
        // Shown in the Informer when a link to a file was copied to the clipboard.
        copyLinkToClipboardSuccess: 'Link copied to clipboard.',
        // Used when a link cannot be copied automatically — the user has to select the text from the
        // input element below this string.
        copyLinkToClipboardFallback: 'Copy the URL below',
        // Used as the hover title and screen reader label for buttons that copy a file link.
        copyLink: 'Copy link',
        back: 'Back',
        // Used as the screen reader label for buttons that remove a file.
        removeFile: 'Remove file',
        // Used as the screen reader label for buttons that open the metadata editor panel for a file.
        editFile: 'Edit file',
        // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
        editing: 'Editing %{file}',
        // Used as the screen reader label for the button that saves metadata edits and returns to the
        // file list view.
        finishEditingFile: 'Finish editing file',
        saveChanges: 'Save changes',
        // Used as the label for the tab button that opens the system file selection dialog.
        myDevice: 'My Device',
        dropHint: 'Drop your files here',
        // Used as the hover text and screen reader label for file progress indicators when
        // they have been fully uploaded.
        uploadComplete: 'Upload complete',
        uploadPaused: 'Upload paused',
        // Used as the hover text and screen reader label for the buttons to resume paused uploads.
        resumeUpload: 'Resume upload',
        // Used as the hover text and screen reader label for the buttons to pause uploads.
        pauseUpload: 'Pause upload',
        // Used as the hover text and screen reader label for the buttons to retry failed uploads.
        retryUpload: 'Retry upload',
        // Used as the hover text and screen reader label for the buttons to cancel uploads.
        cancelUpload: 'Cancel upload',
        // Used in a title, how many files are currently selected
        xFilesSelected: {
          0: '%{smart_count} file selected',
          1: '%{smart_count} files selected',
        },
        uploadingXFiles: {
          0: 'Uploading %{smart_count} file',
          1: 'Uploading %{smart_count} files',
        },
        processingXFiles: {
          0: 'Processing %{smart_count} file',
          1: 'Processing %{smart_count} files',
        },
        // The "powered by Uppy" link at the bottom of the Dashboard.
        poweredBy: 'Powered by %{uppy}',
        addMore: 'Add more',
        editFileWithFilename: 'Edit file %{file}',
        save: 'Save',
        cancel: 'Cancel',
        dropPasteFiles: 'Drop files here or %{browseFiles}',
        dropPasteFolders: 'Drop files here or %{browseFolders}',
        dropPasteBoth: 'Drop files here, %{browseFiles} or %{browseFolders}',
        dropPasteImportFiles: 'Drop files here, %{browseFiles} or import from:',
        dropPasteImportFolders: 'Drop files here, %{browseFolders} or import from:',
        dropPasteImportBoth:
          'Drop files here, %{browseFiles}, %{browseFolders} or import from:',
        importFiles: 'Import files from:',
        browseFiles: 'browse files',
        browseFolders: 'browse folders',
        recoveredXFiles: {
          0: 'We could not fully recover 1 file. Please re-select it and resume the upload.',
          1: 'We could not fully recover %{smart_count} files. Please re-select them and resume the upload.',
        },
        recoveredAllFiles: 'We restored all files. You can now resume the upload.',
        sessionRestored: 'Session restored',
        reSelect: 'Re-select',
        missingRequiredMetaFields: {
          0: 'Missing required meta field: %{fields}.',
          1: 'Missing required meta fields: %{fields}.',
        },*/
      }
    }
  })

  uppy.on("file-removed", (file, c, d) => {
    console.log("---", file, c, d)
  })
  uppy.on("file-added", () => {
    console.log("file added")
  })
  uppy.on("progress", (progress) => {
    // progress: integer (total progress percentage)
    console.log(progress)
  })
  uppy.on("upload-progress", (file, progress) => {
    // file: { id, name, type, ... }
    // progress: { uploader, bytesUploaded, bytesTotal }
    console.log(file.id, progress.bytesUploaded, progress.bytesTotal)
  })
  uppy.on("upload-success", (file, response) => {
    console.log(file.name, response.uploadURL)
    /*
    const img = new Image()
    img.width = 300
    img.alt = file.id
    img.src = response.uploadURL
    document.body.appendChild(img)
    */
  })
  uppy.on("complete", (result) => {
    console.log("successful files:", result.successful)
    console.log("failed files:", result.failed)
  })
  uppy.on("error", (error) => {
    console.error(error.stack)
  })
  uppy.on("upload-error", (file, error, response) => {
    console.log("error with file:", file.id)
    console.log("error message:", error)
  })
  uppy.on("upload-retry", (fileID) => {
    console.log("upload retried:", fileID)
  })

  uppy.on("retry-all", (fileIDs) => {
    console.log("upload retried:", fileIDs)
  })
  uppy.on("restriction-failed", (file, error) => {
    // do some customized logic like showing system notice to users
  })
  uppy.on("info-visible", () => {
    const { info } = uppy.getState()
    // info: {
    //  isHidden: false,
    //  type: 'error',
    //  message: 'Failed to upload',
    //  details: 'Error description'
    // }
    console.log(`${info.message} ${info.details}`)
  })
  uppy.on("reset-progress", () => {
    // progress was reset
  })
  /*
  useEffect(() => {
    return () => {
      console.log("test")
      uppy.close({ reason: 'unmount' })
    }
  }, [])
  */

  return (
    <div className="App">
      <header className="App-header">
        <div id="myarea" style={{ border: "1px solid silver", marginBottom: "10px" }}>
          test
        </div>
        <Dashboard
          inline={true}
          width={300}
          height={500}
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          waitForThumbnailsBeforeUpload={false}
          showLinkToFileUploadResult={false}
          showProgressDetails={false}
          hideUploadButton={false}
          hideRetryButton={false}
          hidePauseResumeButton={false}
          hideCancelButton={false}
          hideProgressAfterFinish={false}
          showSelectedFiles={true}
          showRemoveButtonAfterComplete={false}
          singleFileFullScreen={true}
          closeModalOnClickOutside={false}
          closeAfterFinish={false}
          disablePageScrollWhenModalOpen={true}
          animateOpenClose={true}
          fileManagerSelectionType={"files"} //files, folders, both
          disableStatusBar={false}
          disableInformer={false}
          disableThumbnailGenerator={false}
          note={"팀소프트"}
        />

        {/*
          <DashboardModal
            uppy={uppy}
            open={true}
            target={document.body}
            //onRequestClose={() => this.setState({ open: false })}
          />
  */}

        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header>
    </div>
  )
}

export default UppyTest
