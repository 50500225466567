import img1 from "../images/box-item/card-item8.jpg"
import imga1 from "../images/avatar/avt-11.jpg"
import img2 from "../images/box-item/image-box-10.jpg"
import imga2 from "../images/avatar/avt-12.jpg"
import img3 from "../images/box-item/card-item-11.jpg"
import imga3 from "../images/avatar/avt-13.jpg"
import img4 from "../images/box-item/image-box-21.jpg"
import imga4 from "../images/avatar/avt-14.jpg"
import imgCollection1 from "../images/avatar/avt-18.jpg"
import imgCollection2 from "../images/avatar/avt-16.jpg"
import imgCollection3 from "../images/avatar/avt-17.jpg"

const liveAuctionData = [
  {
    img: img1,
    title: "Hamlet Contemplates Contemplates ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img2,
    title: "Triumphant Awakening Contemplates ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "220",
    imgCollection: imgCollection2,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img3,
    title: "Living Vase 01 by Lanza Contemplates",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "90",
    imgCollection: imgCollection3,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img4,
    title: "Flame Dress' by Balmain Contemplates ",
    tags: "bsc",
    imgAuthor: imga4,
    nameAuthor: "Tyler Covington",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "145",
    imgCollection: imgCollection1,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img1,
    title: "Hamlet Contemplates Contemplates ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection2,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img2,
    title: "Triumphant Awakening Contemplates ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "220",
    imgCollection: imgCollection3,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img3,
    title: "Living Vase 01 by Lanza Contemplates",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "90",
    imgCollection: imgCollection1,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img1,
    title: "Hamlet Contemplates Contemplates ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img2,
    title: "Triumphant Awakening Contemplates ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "220",
    imgCollection: imgCollection2,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img3,
    title: "Living Vase 01 by Lanza Contemplates",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "90",
    imgCollection: imgCollection3,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img4,
    title: "Flame Dress' by Balmain Contemplates ",
    tags: "bsc",
    imgAuthor: imga4,
    nameAuthor: "Tyler Covington",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "145",
    imgCollection: imgCollection1,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img1,
    title: "Hamlet Contemplates Contemplates ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection2,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img2,
    title: "Triumphant Awakening Contemplates ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "220",
    imgCollection: imgCollection3,
    nameCollection: "Colorful Abstract"
  },
  {
    img: img3,
    title: "Living Vase 01 by Lanza Contemplates",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "90",
    imgCollection: imgCollection1,
    nameCollection: "Colorful Abstract"
  }
]

export default liveAuctionData
