import img1 from "../images/slider/slide_2.png"
import img2 from "../images/slider/slide_2.png"
import imgbg1 from "../images/slider/bg_slide_2.png"
import imgbg2 from "../images/slider/bg_slide_2.png"

const heroSliderData = [
  /*{
    title_1: "Teamsoft !!!",
    title_2: "extraordinary",
    title_3: "Monster ",
    title_4: "NFTs",
    description: "Teamsoft for monster character cllections non fungible token NFTs",
    img: img1,
    imgbg: imgbg1,
    class: "left"
  },*/
  {
    title_1: "웹개발. AI. DevOps. Cloud",
    title_2: "프로젝트형 실전 강의",
    title_3: "CodeTutor",
    title_4: "NFTs",
    description: "여러분의 가치와 Value 업그레이드를 자신합니다.",
    img: img2,
    imgbg: imgbg2,
    class: "center"
  }
  /*,
  {
    title_1: "Discover, find,",
    title_2: "Sell extraordinary",
    title_3: "Monster ",
    title_4: "NFTs",
    description: "Teamsoft for monster character cllections non fungible token NFTs",
    img: img2,
    imgbg: imgbg2,
    class: "right"
  }*/
]

export default heroSliderData
