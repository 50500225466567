import axios from "axios"

const axioxSpring = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
  mode: "no-cors",
  credentials: "same-origin",
  headers: {
    //"Access-Control-Allow-Origin": '*',
    "Content-Type": "application/json"
  }
})

export default axioxSpring
