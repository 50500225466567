import React from "react"
import { Link } from "react-router-dom"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"

const Login = () => {
  const KAKAO_API_KEY = process.env.REACT_APP_KAKAO_API_KEY
  const DOMAIN = process.env.REACT_APP_DOMAIN
  const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI

  const KakaoLogin = () => {
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_API_KEY}&redirect_uri=${DOMAIN}${KAKAO_REDIRECT_URI}&scope=account_email profile_nickname profile_image talk_message`
  }

  return (
    <div>
      <Header />
      <section className="tf-login tf-section">
        <div className="themesflat-container" style={{ marginTop: "50px" }}>
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-1">코드튜터 로그인</h2>

              <div className="flat-form box-login-email">
                <div className="box-title-login" style={{ marginBottom: "10px" }}>
                  <h5>Email Login</h5>
                </div>

                <div className="form-inner">
                  <form action="#" id="contactform">
                    <input id="email" name="email" tabIndex="2" aria-required="true" type="email" placeholder="이메일" required />
                    <input
                      id="password"
                      name="password"
                      tabIndex="1"
                      aria-required="true"
                      required
                      type="password"
                      placeholder="패스워드"
                    />
                    <div className="row-form style-1">
                      <label>
                        Remember me
                        <input type="checkbox" />
                        <span className="btn-checkbox"></span>
                      </label>
                      <Link to="/sign-up" className="forgot-pass">
                        회원가입
                      </Link>
                    </div>

                    <button className="submit">로그인</button>
                  </form>
                </div>
              </div>

              <div className="flat-form box-login-social">
                <div className="box-title-login">
                  <h5>Social Login</h5>
                </div>
                <ul>
                  <li>
                    <Link
                      onClick={KakaoLogin}
                      // eslint-disable-next-line no-template-curly-in-string
                      //to="https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_API_KEY}&redirect_uri=${REDIRECT_URI}&scope=account_email profile_nickname profile_image talk_message"
                      className="sc-button style-2 fl-button pri-3"
                      style={{ background: "#fee500", border: "0" }}
                    >
                      <span style={{ color: "#444" }}>카카오 로그인</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="sc-button style-2 fl-button pri-3" style={{ backgroundColor: "#fff", border: "0" }}>
                      <span style={{ color: "#222" }}>구글 로그인</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Login
