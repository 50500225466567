/* eslint-disable react/react-in-jsx-scope */
import "./App.css"
import { Routes, Route } from "react-router-dom"
import routes from "./pages/index"
import { SWRConfig } from "swr"

function App() {
  const onErrorRetry = (error, key, config, revalidate, { retryCount }) => {
    console.log(retryCount)
    if (error?.status === 404) return

    if (retryCount >= 2) return
    setTimeout(() => revalidate({ retryCount }), 5000)
  }
  return (
    <SWRConfig value={{ onErrorRetry: onErrorRetry, revalidateOnFocus: true, suspense: false, revalidateOnMount: true }}>
      <Routes>
        {routes.map((data, index) => (
          <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
        ))}
      </Routes>
    </SWRConfig>
  )
}

export default App
