import React from "react"
import { Link } from "react-router-dom"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"

const SignUp = () => {
  return (
    <div>
      <Header />
      <section className="tf-login tf-section">
        <div className="themesflat-container" style={{ marginTop: "50px", marginBottom: "104px" }}>
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-1">코드튜터 회원가입</h2>

              <div className="flat-form box-login-email">
                <div className="box-title-login" style={{ marginBottom: "10px" }}>
                  <h5>CodeTutor Member</h5>
                </div>

                <div className="widget widget-search">
                  <form action="#">
                    <div className="input-group mb-3">
                      <input id="name" name="name" tabIndex="1" type="text" placeholder="이름" />
                    </div>

                    <div className="input-group mb-3">
                      <input id="email" name="email" tabIndex="2" type="email" placeholder="이메일" />
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "1.4em", paddingTop: "15px", paddingBottom: "14px" }}
                      >
                        인증메일 발송
                      </button>
                    </div>

                    <div className="input-group mb-3">
                      <input id="phone" name="phone" tabIndex="3" type="number" placeholder="휴대폰번호(숫자만)" />
                    </div>

                    <div className="input-group mb-3">
                      <input id="pass1" name="pass1" tabIndex="4" type="password" placeholder="비밀번호" />
                    </div>

                    <div className="input-group mb-3">
                      <input id="pass2" name="pass2" tabIndex="5" type="password" placeholder="비밀번호 확인" />
                    </div>

                    <div className="input-group mb-3 form-inner">
                      <button className="submit" style={{ borderRadius: "30px" }}>
                        회원가입
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default SignUp
