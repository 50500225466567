import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import img1 from "src/assets/images/box-item/card-item-3.jpg"
import imga1 from "src/assets/images/avatar/avt-1.jpg"

import { useCrsList } from "src/api/CrsApi"

const CrsHomeList = () => {
  const { data, isLoading } = useCrsList()
  const [menuTab] = useState([
    {
      class: "active",
      name: "ALL"
    }
  ])
  const [panelTab, setPanelTab] = useState([
    {
      id: 1,
      dataContent: []
    }
  ])

  const [visible, setVisible] = useState(8)
  const [modalShow, setModalShow] = useState(false)
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4)
  }

  useEffect(() => {
    setPanelTab([
      {
        id: 1,
        dataContent: data
      }
    ])
  }, [isLoading])

  return (
    <Tabs>
      <TabList>
        {menuTab.map((item, index) => (
          <Tab key={index}>{item.name}</Tab>
        ))}
      </TabList>

      <div className="content-tab">
        <div className="content-inner">
          <div className="row">
            {panelTab.map((item, index) => (
              <TabPanel key={index}>
                {item.dataContent.slice(0, visible).map((data, index) => (
                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                    <div className="sc-card-product explode ">
                      <div className="card-media">
                        <Link to="#">
                          <img src={img1} alt="Axies" style={{ height: "140px" }} />
                        </Link>
                        <div className="button-place-bid ">
                          <button
                            onClick={() => setModalShow(true)}
                            className="sc-button style-place-bid style bag fl-button pri-3"
                          >
                            <span>준비중입니다.</span>
                          </button>
                        </div>
                        <Link to="/login" className="wishlist-button heart">
                          <span className="number-like"> {""}</span>
                        </Link>
                      </div>
                      <div className="mg-bt-16">
                        <h5 style={{ textAlign: "center", whiteSpace: "normal !important", marginBottom: "10px" }}>
                          <Link to="#">{data.crs_nm}</Link>
                        </h5>
                        {/*
                        <h7 style={{ textAlign: "center", whiteSpace: "normal !important", fontSize: "1.2em" }}>
                          <Link to="#">{data.crs_nm}</Link>
                        </h7>
                      */}
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={imga1} alt="Axies" />
                          </div>
                          <div className="info">
                            <span>Tutor</span>
                            <h6>
                              {" "}
                              <Link to="#">{"Joel"}</Link>{" "}
                            </h6>
                          </div>
                        </div>
                        <div className="tags">{"tags"}</div>
                      </div>
                      <div className="card-bottom style-explode">
                        <div className="price">
                          {/*
                          <div className="price-details">
                            <h5>{70000}</h5>
                            <span>= {"priceChange"}</span>
                          </div>
                          */}
                        </div>
                        <Link to="/activity-01" className="view-history reload">
                          View History
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                {visible < item.dataContent.length && (
                  <div className="col-md-12 wrap-inner load-more text-center">
                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}>
                      <span>Load More</span>
                    </Link>
                  </div>
                )}
              </TabPanel>
            ))}
          </div>
        </div>
      </div>
    </Tabs>
  )
}

export default CrsHomeList
