import React from "react"
import { Link } from "react-router-dom"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import img1 from "../assets/images/blog/thumb-8.png"

const Contact01 = () => {
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Contact 1</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Contact</Link>
                  </li>
                  <li>Contact 1</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-feature-contact">{/*<img src={img1} alt="Axies" />*/}</div>
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                {/*
                <iframe
                  src="https://customer-0zqgjpvvndiasvl7.cloudflarestream.com/5ad46320caa2117eaa23092f15aa61c6/iframe?poster=https%3A%2F%2Fcustomer-0zqgjpvvndiasvl7.cloudflarestream.com%2F5ad46320caa2117eaa23092f15aa61c6%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                  loading="lazy"
                  style={{ border: "none", position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
                ></iframe>
              */}
                <iframe
                  src="https://customer-0zqgjpvvndiasvl7.cloudflarestream.com/eyJhbGciOiJSUzI1NiIsImtpZCI6IjFmOWRhYjNmNzE3MDk4OWQyNjQ2NjMzMzA0YmM5NThhIn0.eyJzdWIiOiI1YWQ0NjMyMGNhYTIxMTdlYWEyMzA5MmYxNWFhNjFjNiIsImtpZCI6IjFmOWRhYjNmNzE3MDk4OWQyNjQ2NjMzMzA0YmM5NThhIiwiZXhwIjoiMTcwODUwNDQxNCIsIm5iZiI6IjE3MDg1MDA3MzQifQ.D-SY-6Symq7nkzLXQOb1-Gj5p7aEsJ1Puisn3mZ_c-9PacWfY0LCZ9T7Hnc_alBlqBZ4mkbbAAkVPpBCPe9ADCerKo-JazJwLIZLl0lmEqUHCjiooJCjDJCgoIB5gIOC0G-FYL0vmarR82GSugtUb_QmHpjEy7H7UkwMOrUGbRC62Up6s8KjARBBQLUgl0_BxsjgYnrfkycBOrLzsMBK8YzQDmoSPnEJRvi4LVx4FJ6tUqr1c88fsG11tTmZajfVKynEqqtnfFkrToZsIO-gK5fe20rZTWn06ix8CHBgdtKtLBDA0E_UDlcBTpexNTfHz4egVhzUZDVDXCFef4ualA/iframe"
                  style={{ border: "none" }}
                  height="720"
                  width="1280"
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <h2 className="tf-title-heading style-2 mg-bt-12">Drop Up A Message</h2>
              <h5 className="sub-title style-1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum
                officiis deleniti asperiores sit.
              </h5>
              <div className="form-inner">
                <form id="contactform" noValidate="novalidate" className="form-submit">
                  <input
                    id="name"
                    name="name"
                    tabIndex="1"
                    aria-required="true"
                    type="text"
                    placeholder="Your Full Name"
                    required
                  />
                  <input
                    id="email"
                    name="email"
                    tabIndex="2"
                    aria-required="true"
                    type="email"
                    placeholder="Your Email Address"
                    required
                  />
                  <div className="row-form style-2" id="subject">
                    <select>
                      <option value="1">Select subject</option>
                      <option value="2">Select subject</option>
                      <option value="3">Select subject</option>
                    </select>
                    <i className="icon-fl-down"></i>
                  </div>
                  <textarea
                    id="message"
                    name="message"
                    tabIndex="3"
                    aria-required="true"
                    required
                    placeholder="Message"
                  ></textarea>
                  <button className="submit">Send message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact01
