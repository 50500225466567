const menus = [
  {
    id: 1,
    name: "코드튜터소개",
    links: "#"
  },
  {
    id: 2,
    name: "강의코스",
    links: "#"
  },
  {
    id: 3,
    name: "로드맵",
    links: "#"
  },
  {
    id: 4,
    name: "QnA",
    links: "#"
  },
  {
    id: 5,
    name: "문의하기",
    links: "#"
  }
]

export default menus
