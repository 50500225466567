import ItemDetails01 from "./ItemDetails01"
import ItemDetails02 from "./ItemDetails02"
import Home from "./Home"
import Login from "./Login"
import KakaoLogin from "../components/login/KaKaoLogin"
import Logout from "src/components/login/Logout"
import SignUp from "./SignUp"
import NoResult from "./NoResult"
import FAQ from "./FAQ"
import Contact01 from "./Contact01"
import Contact02 from "./Contact02"
import UppyTest from "./UppyTest"

const routes = [
  { path: "/", component: <Home /> },
  { path: "/kakao-login", component: <KakaoLogin /> },
  { path: "/logout", component: <Logout /> },
  { path: "/item-details-01", component: <ItemDetails01 /> },
  { path: "/item-details-02", component: <ItemDetails02 /> },
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <SignUp /> },
  { path: "/no-result", component: <NoResult /> },
  { path: "/faq", component: <FAQ /> },
  { path: "/contact-01", component: <Contact01 /> },
  { path: "/contact-02", component: <Contact02 /> },
  { path: "/uppy", component: <UppyTest /> }
]

export default routes
